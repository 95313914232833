import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import cookie from "../images/home/slider/2019/may/cookie.jpg";
import buildYourOwn from "../images/home/static/cards/build-your-own.jpg";
import careers from "../images/home/static/cards/careers-pizzaguys.jpg";
import hotDeals from "../images/home/static/cards/hot-deals.jpg";
import locations from "../images/home/static/cards/locations.jpg";
import {Link} from "gatsby";
import Main from "../components/atoms/main";
import Section from "../components/atoms/section";
import ButtonLink from "../components/atoms/button-link";
import Card from "../components/atoms/card";
import {link_locations, link_order, link_specials, link_pizza_calculator} from "../helpers/constants";

function IndexPage() {
    return (
        <Layout useMain={false}>
            <SEO
                title="Home"
                keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
            />

            <Main>
                <Section color={'bg-white'} className={'text-center'}>
                    <img
                        src={cookie}
                        className=" mx-auto "
                        alt="Cat and human sitting on a couch"
                    />
                </Section>

                <Section color={'bg-white'} className={'text-center'}>
                    <div className="flex flex-wrap ">
                        <div className="w-full xs:w-full sm:w-full md:w-1/2 lg:w-1/2 xl:w-1/2 pr-2 ">
                            <img
                                src={cookie}
                                className=" mx-auto"
                                alt="Cat and human sitting on a couch"
                            />
                        </div>

                        <div className="w-full xs:w-full sm:w-full md:w-1/2 lg:w-1/2 xl:w-1/2 pl-2 ">
                            <img
                                src={cookie}
                                className=" mx-auto "
                                alt="Cat and human sitting on a couch"
                            />
                        </div>
                    </div>
                </Section>


                <Section className={''}>
                    <div className="flex flex-wrap pt-6 pb-6">
                        <div className="w-full xs:w-1/2 sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 ">
                            <Card image={buildYourOwn}
                                  title={'Build Your Own'}
                                  link={link_order}
                                  text={'Try something new every time with over one million combinations.'}
                                  button={'Start Order'}></Card>
                        </div>

                        <div className="w-full xs:w-1/2 sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 ">
                            <Card image={hotDeals} title={'Specials'}
                                  link={link_specials}
                                  text={'Save big on all your favorite Pizza Guys food. Check out our Specials page.'}
                                  button={'See Deals'}></Card>
                        </div>

                        <div className="w-full xs:w-1/2 sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 ">
                            <Card image={locations}
                                  link={link_locations}
                                  title={'Locations'}
                                  text={'60+ locations to serve you. Find your favorite Pizza Guys using the store locator.'}
                                  button={'Find Your Pizza Guys'}></Card>
                        </div>

                        <div className="w-full xs:w-1/2 sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 ">
                            <Card image={careers}
                                  title={'Careers'}
                                  text={'Got what it takes? Apply now and be part of the Pizza Guys Family!'}
                                  button={'Apply Now'}></Card>
                        </div>

                    </div>
                </Section>

                <Section color={'bg-white'} className={''}>
                    <div className="flex flex-wrap">
                        <div className="w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2 mb-4 pr-8">
                            <h4 className={'text-4xl font-bold mb-3'}>Friendly Pizza Delivery</h4>
                            <p className={'text-gray-700'}>Order Pizza Guys for lunch, dinner, or late-night delivery
                                anytime. We have 60+ locations
                                available to serve you – hot, fresh and artisan made.</p>
                            <ButtonLink link={link_order}>Order Now</ButtonLink>
                        </div>
                        <div
                            className="w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2 mb-4 pl-8 border-l-2 border-gray-300 ">
                            <h4 className={'text-4xl font-bold mb-3'}>Party Time?</h4>
                            <p className={'text-gray-700'}>Don’t know how many pizzas are enough? Don’t leave anyone
                                hungry. Use our pizza
                                calculator to help you plan the ultimate feast!</p>
                            <ButtonLink link={link_pizza_calculator}>Calculate</ButtonLink>
                        </div>
                    </div>
                </Section>

                <Section color={'bg-red-800 p-4'} className={''}>
                    <div className={'text-center'}>
                        <Link className={'bg-red-700 p-4 pr-10 pl-10 rounded text-white font-bold text-2xl uppercase'}
                              to={link_order}>Start Your Order</Link>
                    </div>
                </Section>
            </Main>
        </Layout>
    );
}

export default IndexPage;
