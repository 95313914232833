import React from "react";
import PropTypes from "prop-types";
import {Link} from "gatsby";
import ButtonLink from "./button-link";
import Section from "./section";

function Card({image, imageAlt, title, text, button, link}) {


    return (
        <div className={'bg-white shadow m-2 rounded'}>
            <img
                src={image}
                className="block mx-auto"
                alt={imageAlt}
            />
            <div className={'m-2 rounded p-4'}>
                <h2 className={'text-xl font-bold mb-3'}>{title}</h2>
                <p className={'text-gray-700'}>{text}</p>
                <ButtonLink link={link}>{button}</ButtonLink>
            </div>
        </div>
    );
}

Card.propTypes = {
    children: PropTypes.node.isRequired
};

export default Card;
